import { LAYOUT_ROUTE } from "src/app/constants/route.constants"

export const DASHBOARD_ROUTE = {
    path: 'conferences',
    get url() {
        return `/${this.path}`
    }
}
export const REGISTRATION_ROUTE = {
    path: 'registration',
    param: 'conferenceId',
    get url(): string {
        return `/${this.path}`
    },
     urlWithId(conferenceId:string) {
        return `/${this.path}/${conferenceId}`
    }
}